import React from 'react'

import Layout from "../components/layout"
import Seo from "../components/seo"

// import { css } from "@emotion/core"

const MerciPage = (props) => {

    return (
        <Layout>
            <Seo title="Merci" />

            <div className="container">
                <div className="hero-section">
                    <h1>Merci, notre équipe prend le relai !</h1>
                    <p>Nous serons en contact dans les meilleurs délais.</p>
                </div>
            </div>
        </Layout>
    )
}

export default MerciPage